<template>
  <div class="main-box" style="padding: 0">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="createOk()">新建记录</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="week"
          label="日期"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.c_type==1">
            {{scope.row.start_date}}
          </div>
          <div v-if="scope.row.c_type==2">
            {{scope.row.start_date.substring(0,7)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">未开始</span>
          <span v-if="scope.row.status==1">进行中</span>
          <span v-if="scope.row.status==2">已完成</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="goDetail(scope.row)">详情</el-button>
<!--          <el-popconfirm v-if="scope.row.status==0"-->
<!--              title="确认删除本次记录吗？"-->
<!--              @confirm="delWeek(scope.row.id)"-->
<!--          >-->
<!--            <el-button slot="reference" type="text" style="color: red; margin-left: 10px;" size="small">删除</el-button>-->
<!--          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      dialogPayVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        store: '',
        c_type: ''
      },
      tableData: [],
      storeList: []
    };
  },
  props: {
    cType: Number,
    shopId: String || Number
  },
  watch: {
    cType: {
      handler: function(newVal, oldV) {
        if(this.search.c_type!=newVal) {
          this.search.c_type = newVal
          setTimeout(res => {
            this.getList();
          },300)
        }
      },
      deep: true,
      immediate: true
    },
    shopId: {
      handler: function(newVal, oldV) {
        if(this.search.store!=newVal) {
          this.search.store = newVal
          setTimeout(res => {
            this.getList();
          },300)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeType(e) {
      this.search.current_page = 1;
      this.getList()
    },
    addGroup() {
      this.dialogPayVisible = true
    },
    createOk() {
      this.$emit('callFunc');
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        store: that.search.store,
        c_type: that.search.c_type
      }
      this.$api.operate.financeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    delWeek(id) {
      this.$api.operate.financeDel({id:id}, res => {
        if(res.errcode==0) {
          this.success('删除成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    goDetail(item) {
      this.$router.push({name: 'operate_finance_show', query: {id: item.id}})
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
